.navbar {
  background-color: transparent;
  /* color: "white"; */
}

.navbar.colorChange {
  /* color: "black"; */
  background-color: #e04d01;
}
.Nav {
  padding: 0px 30px;
  background-color: #000;
  width: 100%;
}
.mobileNav {
  padding: 0px 0px;
  background-color: #000;
  width: 100%;
}
.txt {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 10px !important;
}
.mobiletxt {
  align-items: center;
  text-align: center;
  font-size: 2px !important;
}
.img {
  height: 80px;
  padding: 10px 0px 10px 100px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobileImg {
  display: none;
}
.img2 {
  height: 60px;
  padding: 10px 0px 10px 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-content: center;
}

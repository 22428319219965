@import url("https://fonts.googleapis.com/css2?family=Patua+One&family=Roboto:wght@100;400;500;700;900&family=Teko:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@400;500;600;700;800;900&family=Montserrat+Alternates:wght@300&family=Montserrat:wght@400;500;600;700&display=swap");

@import url(../src/css/indexpage/IndexPageStyles.css);

.App {
  text-align: center;
}
html {
  scroll-behavior: smooth;
}

/* Background */
.indexbanner {
  background: linear-gradient(
      315deg,
      rgba(39, 25, 25, 0.589),
      rgba(37, 14, 14, 0.664)
    ),
    url(./assets/indexbanner2.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  width: 100%;
  height: 740px;
}
.mobileindexbanner {
  background-image: linear-gradient(
      315deg,
      rgba(109, 76, 153, 0.589),
      rgba(255, 255, 255, 0.412)
    ),
    url(./assets//indexbanner2.jpg);
  /* background-size: cover; */
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  width: 100%;
  height: 520px;
  margin-top: 0px;
}

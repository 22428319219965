@import url("https://fonts.googleapis.com/css2?family=Patua+One&family=Roboto:wght@100;400;500;700;900&family=Teko:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@400;500;600;700;800;900&family=Montserrat+Alternates:wght@300&family=Montserrat:wght@400;500;600;700&display=swap");

/* ============Header============ */
.heroMainText {
  color: white;
  font-size: 50px;
  font-weight: 700;
}

.heroText {
  width: "100%";
  height: "100%";
  display: "flex";
  flex-direction: "column";
  justify-content: "center";
  align-items: "center";
  gap: "20px";
  margin-left: "100px";
  margin-top: 100px;
}
.mobileHeroText {
  width: "100%";
  height: "100%";
  display: "flex";
  flex-direction: "column";
  justify-content: "left";
  align-items: "left";
  gap: "20px";
  margin: 100px 30px 0px 30px;
}

.HeaderText {
  font-weight: 900 !important;
  font-size: 70px !important;
  color: #fff !important;
  text-align: center;
  text-transform: capitalize !important;
  /* font-family: "Exo" !important; */
}
.mobileHeaderText {
  font-weight: 900 !important;
  font-size: 30px !important;
  color: #fff !important;
  text-align: left;
  text-transform: capitalize;
  /* font-family: "Exo" !important; */
}
.HeaderText2 {
  font-weight: 900 !important;
  font-size: 70px !important;
  color: #ee9d1c !important;
  text-align: center;
  text-transform: capitalize;
  /* font-family: "Exo" !important; */
}
.mobileHeaderText2 {
  font-weight: 900 !important;
  font-size: 45px !important;
  color: #fff !important;
  text-align: left;
  text-transform: capitalize;
  /* font-family: "Exo" !important; */
}
.HeaderText3 {
  text-align: center;
  color: white;
}
.mobileHeaderText3 {
  text-align: center;
  color: #fff;
}
.hBTN {
  border-color: #fff !important;
  color: #fff !important;
}
.hBTN:hover {
  border-color: #f94a29 !important;
  color: #f94a29 !important;
}
/* .mobilehBTN {
  border-color: #fff !important;
  color: #fff !important;
}
.mobilehBTN:hover {
  border-color: #2a2550 !important;
  color: #2a2550 !important;
} */
.hBTN2 {
  background-color: #f94a29 !important;
  color: #fff !important;
}
.hBTN2:hover {
  background-color: #ff7e00 !important;
  color: #fff !important;
}
/* .mobilehBTN2 {
  background-color: #261d3a !important;
  color: #fff !important;
}
.mobilehBTN2:hover {
  background-color: #2a2550 !important;
  color: #fff !important;
} */
/* ========================= */
/* ========MMSC Service===== */
.MMSCServ {
  background-color: #f0eeed;
  width: 100%;
  padding: 50px 0px;
}
.mobileMMSCServ {
  padding: 50px 50px;
}
.mobileMMSCServImg {
  width: 100% !important;
  /* padding: 10px !important; */
  border-radius: 5px;
}
.icon {
  color: #000;
  text-align: center;
}

.text {
  font-size: 20px;
  font-family: "Exo";
  /* font-weight: 800; */
  color: #000;
  text-align: center;
  padding-top: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

/* ========================== */
/* =========About============= */

.About {
  padding: 50px 100px 30px 100px;
  background-color: #222831;
}
.mobileAbout {
  padding: 20px;
  background-color: #222831;
  /* background-color: #a5c4f27a; */
}
.iconHead {
  color: #e04d01 !important;
  font-size: 32px !important;
}
/* .mobileaiconHead {
  color: #2a2550 !important;
  font-size: 32px !important;
} */
.titleHead {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e04d01 !important;
}
/* .mobiletitleHead {
  display: flex;
  justify-content: center;
  align-items: center !important;
  color: #2a2550 !important;
} */
.titleText {
  font-size: 30px !important;
  font-weight: 900 !important;
  margin-top: 25px;
  color: #ff7e00 !important;
}
/* .mobiletitleText {
  font-size: 30px !important;
  font-weight: 900 !important;
  margin-top: 25px;
  color: #261d3a !important;
} */
.titleText2 {
  text-align: justify;
  margin-top: 25px;
  color: #fff !important;
}
/* .mobiletitleText2 {
  text-align: justify;
  margin-top: 25px;
  color: #261d3a79 !important;
} */
.iconCard {
  color: #fff !important;
  font-size: 60px !important;
  background-color: #e04d01 !important;
  border-radius: 10px !important;
}
/* .mobileiconCard {
  color: #fff !important;
  font-size: 60px !important;
  background-color: #261d3a !important;
  border-radius: 10px !important;
} */
.AboutText {
  display: flex;
  justify-content: justify;
  align-items: center;
  font-size: 15px !important;
  font-weight: bolder !important;
  color: #ff7e00 !important;
}
.mobileAboutText {
  display: flex;
  text-align: justify;
  justify-content: justify;
  align-items: center;
  font-size: 10px !important;
  font-weight: bolder !important;
  color: #fff !important;
}
.Feedback {
  padding: 20px;
  margin-left: 5px;
  background-color: #f8f8f8 !important;
  border-radius: 10px !important;
  border-left-color: #e04d01 !important;
  border-left-style: solid;
  border-left-width: 5px;
}
.mobileFeedback {
  padding: 20px;
  margin-left: 5px;
  background-color: #f8f8f8 !important;
  border-radius: 10px !important;
  border-left-color: #e04d01 !important;
  border-left-style: solid;
  border-left-width: 5px;
}
.quote {
  font-size: 50px !important;
  color: #e04d01 !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 50% !important;
}
.mobilequote {
  font-size: 50px !important;
  color: #e04d01 !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 50% !important;
}
/* ============================ */

/* ==========Service=========== */
.solutionbanner {
  background-image: url(../../assets/service_bg.jpg);
  background-size: cover;
  background-position: center;
  width: 100%;
}
.Serv {
  padding: 50px 100px 30px 100px;
  align-content: center;
}
.mobileServ {
  padding: 30px;
}
.ServText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 6vh !important;
  font-weight: 900 !important;
  /* font-family: "Exo" !important; */
  color: #e04d01;
}
.mobileServText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px !important;
  font-weight: 900 !important;
  /* font-family: "Exo" !important; */
  color: #000000;
}

/* Serv Card */
.ServCard {
  height: 210px;
  border-radius: 10px !important;
  margin-top: 15px;
  border-top-color: #e04d01;
  border-top-style: solid;
  border-top-width: 5px;
  cursor: pointer;
  transition: 0.4s linear;
}
.ServCard:hover {
  transform: scale(1.15);
  border-top-color: #000;
  border-top-style: solid;
  border-top-width: 5px;
}
.mobileCardServ {
  height: 280px;
  border-radius: 20px !important;
  margin-top: 15px;
  border-top-color: #261d3a;
  border-top-style: solid;
  border-top-width: 5px;
}
.ServCardT {
  color: #e04d01;
  font-size: 40px;
  text-align: center;
}
.mobileCardServT {
  color: #000 !important;
  text-align: center;
}
.ServDes {
  opacity: 80%;
  text-align: justify;
  font-size: 15px;
}
.mobileServDes {
  opacity: 80%;
  text-align: left;
  font-size: 12px !important;
}

/* =========================== */
/* =========Get in Touch========== */
.callusbanner {
  background-image: url(../../assets/callUs_bg.jpg);
  background-size: cover;
  width: 100%;
}

.GIT {
  padding: 50px 100px 30px 100px;
  align-content: center;
}
.mobileGIT {
  padding: 20px 10px;
}
.GITtxt {
  color: #fff;
  font-size: 6vh !important;
  font-weight: 900 !important;
  text-align: start;
  /* font-family: "Exo" !important; */
}
.mobileGITtxt {
  color: #fff;
  font-size: 4vh !important;
  font-weight: 900 !important;
  text-align: center;
  /* font-family: "Exo" !important; */
}
.GITBtn {
  padding: 20px !important;
  color: #f94a29 !important;
  background-color: #fff !important;
  cursor: pointer !important;
  align-items: center;
}
.GITBtn:hover {
  color: #fff !important;
  background-color: #000 !important;
}
.mobileGITBtn {
  padding: 10px !important;
  color: #f94a29 !important;
  background-color: #fff !important;
  cursor: pointer !important;
  align-items: center;
}
.mobileGITBtn:hover {
  color: #fff !important;
  background-color: #000 !important;
}
/* ============================== */
/* ========Why Choose Us========= */
.Why {
  padding: 50px 100px;
  align-content: center;
}
.mobileWhy {
  padding: 50px 10px;
}
.WhyHead {
  display: flex;
  color: #000;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 40px !important;
  font-weight: 900 !important;
  padding: 0px 100px;
  /* font-family: "Exo" !important; */
}
.mobileWhyHead {
  display: flex;
  color: #000;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px !important;
  font-weight: 900 !important;
  /* font-family: "Exo" !important; */
}
.WhyHeadMission {
  padding: 30px 100px;
}
.mobileWhyMission {
  align-items: center;
  align-content: center;
  justify-content: center;
  display: flex;
}
.Img {
  border-radius: 10px;
  width: 90%;
  height: 380px;
}
.mobileImgmv {
  border-radius: 10px;
  width: 100%;
  height: 180px;
}
.txt4 {
  font-size: 30px !important;
  font-weight: 700 !important;
  /* font-family: "Exo" !important; */
}
.mobiletxt4 {
  font-size: 20px !important;
  font-weight: 700 !important;
  /* font-family: "Exo" !important; */
  text-align: center;
}
.txt2 {
  font-size: 15px !important;
  text-align: justify;
  justify-content: justify;
}
.mobiletxt2 {
  font-size: 12px !important;
  text-align: justify;
  justify-content: justify;
}
/* =============================== */

/* ======Achievements============= */
.Achievementbg {
  background-image: url(../../assets/service_bg.jpg);
  background-size: cover;
  background-position: center;
  width: 100%;
}
.achievements {
  padding: 60px 120px;
}
.mobileAchieve {
  padding: 30px;
}
.achieveTxt {
  font-size: 50px !important;
  font-weight: 900 !important;
  margin-top: 25px;
  /* font-family: "Exo" !important; */
}
.mobileAchieveTxt {
  font-size: 30px !important;
  font-weight: 900 !important;
  margin-top: 25px;
  /* font-family: "Exo" !important; */
}
.AchieveImg {
  width: 100%;
  height: 100%;
  padding-top: 100px;
}
.mobileAchieveImg {
  display: none;
}
/* ================================ */
/* ========Contact Us============== */
.contacttxt {
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 40px !important;
  font-weight: 900 !important;
  padding: 0px 100px 0px 100px;
  /* font-family: "Exo" !important; */
}
.mobilecontacttxt {
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 30px !important;
  font-weight: 900 !important;
  /* font-family: "Exo" !important; */
}
/* ================================= */

.card_title {
  font-weight: 500 !important;
  margin-bottom: 5px !important;
  font-size: 20px !important;
  font-family: "Patua One", cursive;
}
.card_icons {
  color: #e04d01 !important;
  font-size: 30px !important;
  width: 50px;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
}

.card_text {
  opacity: 80%;
  text-align: justify !important;
}

/* .teamMember {
  background-image: url(../../assets/team_bg.png);
  background-size: contain;
} */

.feedback {
  background-image: url(../../assets/feedback.png);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: auto;
}
.contact {
  background-blend-mode: overlay;
  background-image: url(../../assets/contactbg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  /* padding: 30px 100px 50px 100px; */
}

/* =========Footer========= */
.fpadding1 {
  padding: 50px 100px;
}
.mobilefpadding1 {
  padding: 50px 50px;
}
